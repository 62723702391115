<template>
  <div class="auth">
    <Header :title="topTitle" :back="true"/>
   
    <div class="box-items" style="position:relative">
      <el-input
        class="form-items"
        placeholder="请输入旧密码"
        v-model="password1"
        type="password"
        @focus="tips=''"
        prefix-icon="el-icon-lock"
        clearable>
      </el-input>
      <el-input
        class="form-items"
        @focus="tips=''"
        placeholder="请输入新密码"
        v-model="password2"
        type="password"
        prefix-icon="el-icon-lock"
        clearable>
      </el-input>
      <div v-if="tips" class="error-tips"><i class="el-icon-error"></i>{{tips}}</div>
      <div class="form-items"><button class="wid-btn" style="width:96%" @click="handleChange">确认重置</button></div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header'
export default {
  components: {
    Header
  },
  data(){
    return{
      topTitle:'重置交易密码',
      type:this.$route.query.type,
      tips:'',
      password1:'',
      password2:''
     
    }
  },
  
 
  created(){
    if(this.type=='tradepassword'){
      this.topTitle='重置交易密码'
    }else if(this.type=='loginpassword'){
      this.topTitle='重置登录密码'
    }
  },
  methods:{
    
    handleChange(){
      if(!this.password1 || !this.password2){
        this.tips = "请输入新/旧密码"
        return false
      }
      if(this.type=='tradepassword'){
        if(!(/^[0-9]{6}$/.test(this.password2)) ){
          this.tips = "交易密码为6位的数字"
          return false
        }
      } 
      if(this.type=='loginpassword'){
        if(this.checkPwd(this.password2)==false){
          this.tips = "登录密码格式为长度6～16的数字字母"
          return false
        }
      } 
      let params = {
        type:this.type,
        account:'my',
        
      }
      if(this.type=='loginpassword'){
        params.password = this.password2// 新密码params、
        params.oldpassword =this.password1
      }else{
        params.tradepassword = this.password2//交易密码
        params.oldpassword =this.password1
      }
      this.$store
        .dispatch('auth/updateUser',params)
        .then(() => {
          this.$router.replace({name:"secure"})
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.auth{
  .title-pwd{
    // background: #ffffff;
    padding: 1rem 5%;
    color: #464646;
    line-height: 2;
    font-size: .9rem;
  }
  .box-items{
    margin-top:2.5%
  }
  .form-items{
    margin: 1rem auto 0px;
  }
  .checkbox-box{
    font-size: .8rem;
    color: #464646;
    margin-top:3px;
   
  }
}
.draw-box{
  padding:2% 3%;
  :not(:first-child)div{
    margin-bottom:10px;
    margin-left:10%;
    margin-right:10%;
    
  }
  .close-icon{
    text-align: right;
    font-size: 1.5rem;
    margin-bottom:0px !important
  }
}
.draw-title{
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #989898;
  text-align: center;
  padding-top:5px;
  padding-bottom:5px
}
.code-btn{
  position: absolute;
  right: 20px;
  top:43px;
  z-index: 2;
  font-size: .8rem;
  cursor: pointer;
}
</style>